.field-label {
    width: 15%;
}

.top-margin10 {
    margin-top: 10px;
}

.top-margin25 {
    margin-top: 25px;
}

.y-margin25 {
    margin: 25px 0px;
}

.x-padding25 {
    margin: 0px 25px;
}
.top-margin30 {
    margin-top: 40px;
}

.top-margin40 {
    margin-top: 40px;
}

.left-margin10 {
    margin-left: 10px;
}

.left-margin20 {
    margin-left: 20px;
}

.left-margin25 {
    margin-left: 25px;
}

.left-margin40 {
    margin-left: 40px;
}

.left-margin {
    margin-left: 15.5rem;
}

.top-margin10 {
    margin-top: 10px;
}

.top-margin25- {
    margin-top: -25px;
}

.left-padding130 {
    padding-left: 130px;
}

.field {
    width: 800px;
    
}



.tooltip-padding {
    margin-left: 5px;
}

.label {
    font-size: 18px;
}
.sublabel {
    font-size: 13px;
    font-style:italic;
    color:lightgray;
    font-weight:400
}

.link {
    color: black;
}

#create-post-form input,
#create-post-form textarea,
#create-post-form label,
#create-post-form .ms-TextField-suffix {
    color: black !important;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-prepend {
    margin-right: -1px;
}
.flex-container {
    display: flex;
    background-color: #f1f1f1;
}

.flex-container > div {
    background-color: DodgerBlue;
    color: white;
    width: 100px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}

.cmd-btn {
    border: 1px solid rgb(100, 100, 100);
}

.cmd-btn:disabled {
    background-color: rgb(242, 242, 242);
    border: none;
    color: black;
}

div[id^="ViewSelector"] {
    z-index: 20;
}
#__flyoutRootNode .flexbox {
    z-index: 20;
}

.sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.addOwnerFooter {
    text-align: right;
    width: 95%;
    padding-top: 20px;
}
.active {
    background: lightgray;
}

.suggestions li:hover {
    background: lightgray;
}

.input-group-prepend {
    margin-right: 0;

}


@keyframes ellipsis {
    0% { content: ""; }
    25% { content: "."; }
    50% { content: ".."; }
    75% { content: "..."; }
    100% { content: ""; }
}

.typewriter:after {
    content: "";
    animation: ellipsis 2s infinite;
}

.typewriter-container {
    width: max-content;
  }